/* eslint-disable prettier/prettier */
import { Grid, makeStyles, RACAlert, Snackbar } from '@rentacenter/racstrap';
import React, { useState, useCallback, useEffect } from 'react';
import { emailReceipts } from '../../../api/payments';
import { ApiStateWrapper } from '../../../common/ApiStateWrapper/ApiStateWrapper';
import { Footer } from '../../../common/Footer/Footer';
import { EMAIL_RECEIPTS_SUCCESS_MESSAGE } from '../../../constants/constants';
import { CustomerAlertsProvider } from '../../../context/CustomerAlert/CustomerAlertsProvider';
import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
import { CustomerHeaderProvider } from '../../../context/CustomerHeader/CustomerHeaderProvider';
import { CustomerPaymentSummaryProvider } from '../../../context/CustomerPaymentSummary/CustomerPaymentSummaryProvider';
import { useUserPermissions } from '../../../context/permission/PermissionsProvider';
import { PrintLetterProvider } from '../../../context/PrintLetter/PrintLetterProvider';
import { useStoreDetails } from '../../../context/Store/StoreProvider';
import { TextConversationProvider } from '../../../context/TextConversationProvider/TextConversationProvider';
import { WorkedHistoryProvider } from '../../../context/WorkedHistory/WorkedHistoryProvider';
import { LimitedSet } from '../../../domain/LimitedSet';
import { ContactInformation } from '../ContactInformation/ContactInformation';
import { CustomerHeader } from '../CustomerInformation/CustomerHeader';
import { CustomerInformation } from '../CustomerInformation/CustomerInformation';
import { CustomerInformationFooter } from '../CustomerInformation/CustomerInformationFooter';
import { History } from '../History/History';
import { TextConversation } from '../TextConversation/TextConversation';

export const useStyles = makeStyles(() => ({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
}));

const useEmailReceiptsHandler = (
  selectedReceipts: LimitedSet<string | number | undefined>,
  setSelectedReceipts: (set: LimitedSet<string | number | undefined>) => void,
  setDisableEmailReceipts: (disabled: boolean) => void,
  customerId: string | undefined,
  setShowEmailReceiptsSuccess: (success: boolean) => void
): (() => Promise<void>) =>
  useCallback(async () => {
    if (!customerId) {
      return;
    }
    setDisableEmailReceipts(true);
    try {
      await emailReceipts(customerId, [...selectedReceipts].map(String));
      setSelectedReceipts(new LimitedSet(10));
      setShowEmailReceiptsSuccess(true);
    } finally {
      setDisableEmailReceipts(false);
    }
  }, [
    selectedReceipts,
    setSelectedReceipts,
    setDisableEmailReceipts,
    customerId,
    setShowEmailReceiptsSuccess,
  ]);

export const CustomerDetails = () => {
  const ownClasses = useStyles();
  const {
    loading,
    hasApiError,
    customerDetails: { customerId },
  } = useCustomerDetails();
  const { hasAccessToTextConversation } = useUserPermissions();
  const customerDetailLoaded =
    loading !== undefined && !loading && !hasApiError;
  const [selectedReceipts, setSelectedReceipts] = useState<
    LimitedSet<string | number | undefined>
  >(new LimitedSet(10));
  const [disableEmailReceipts, setDisableEmailReceipts] = useState(false);
  const [showEmailReceiptsSuccess, setShowEmailReceiptsSuccess] = useState(false);
  const { maxReceiptsEmailable } = useStoreDetails();

  useEffect(() => setSelectedReceipts(new LimitedSet(maxReceiptsEmailable)), [maxReceiptsEmailable]);

  const handleEmailReceipts = useEmailReceiptsHandler(
    selectedReceipts,
    setSelectedReceipts,
    setDisableEmailReceipts,
    customerId,
    setShowEmailReceiptsSuccess
  );

  const handleCloseEmailReceiptsSuccess = useCallback(() => setShowEmailReceiptsSuccess(false), []);

  return (
    <>
    <CustomerPaymentSummaryProvider>
      <ApiStateWrapper
        loading={loading}
        hasApiError={hasApiError}
        successContent={<></>}
        classes={{
          loader: ownClasses.contentHeight,
          apiError: ownClasses.contentHeight,
        }}
      />
      {customerDetailLoaded && (
        <WorkedHistoryProvider>
          <TextConversationProvider>
          <CustomerHeaderProvider>
              <CustomerAlertsProvider>
                <CustomerHeader />
              </CustomerAlertsProvider>
              <CustomerInformation />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                    <ContactInformation />
                </Grid>
                {hasAccessToTextConversation && (
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                      <TextConversation />
                  </Grid>
                )}
              </Grid>

              <History
                selectedReceipts={selectedReceipts}
                setSelectedReceipts={setSelectedReceipts}
                disableEmailReceipts={disableEmailReceipts}
              />
              <Footer>
                <PrintLetterProvider>
                  <CustomerInformationFooter
                    disableEmailReceiptsButton={
                      selectedReceipts.size === 0 || disableEmailReceipts
                    }
                    handleEmailReceipts={handleEmailReceipts}
                  />
                </PrintLetterProvider>
              </Footer>
          </CustomerHeaderProvider>
          </TextConversationProvider>
        </WorkedHistoryProvider>
      )}
      {(hasApiError || loading) && (
        <Footer>
          <PrintLetterProvider>
            <CustomerInformationFooter
              disableEmailReceiptsButton={
                selectedReceipts.size === 0 || disableEmailReceipts
              }
              handleEmailReceipts={handleEmailReceipts}
            />
          </PrintLetterProvider>
        </Footer>
      )}
    </CustomerPaymentSummaryProvider>
    <Snackbar
      open={showEmailReceiptsSuccess}
      autoHideDuration={6000}
      onClose={handleCloseEmailReceiptsSuccess}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <RACAlert
        handleClose={handleCloseEmailReceiptsSuccess}
        severity="success"
        variant="filled"
        AlertMessage={EMAIL_RECEIPTS_SUCCESS_MESSAGE}
      />
    </Snackbar>
    </>
  );
};
